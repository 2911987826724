import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Home.vue";
const role = localStorage.getItem("role");

let routes = [
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    meta: { requiresAuth: false, title: "Sahifa Topilmadi" },
    component: () => import("../components/NotFound/NotFound.vue"),
  },
  {
    path: "/",
    name: "login",
    meta: { requiresAuth: false, title: "Login" },
    component: () => import("../views/Login/Login.vue"),
  },
  {
    path: "/home",
    name: "home",
    meta: { requiresAuth: false, title: "Loading" },
    component: HomeView,
  },
  {
    path: "/main",
    name: "main",
    meta: { requiresAuth: true, title: "Asosiy", role: "admin" },
    component: () => import("../views/Main.vue"),
  },

  {
    path: "/filiallar",
    name: "filiallar",
    meta: { requiresAuth: true, title: "Filiallar", role: "admin" },
    component: () => import("../views/Filiallar/Filial.vue"),
  },
  {
    path: "/filialichi/:id",
    name: "filialichi",
    meta: { requiresAuth: true, title: "Filialichi", role: "admin" },
    component: () => import("../views/Filiallar/filialichi.vue"),
  },
  {
    path: "/hodimlar",
    name: "hodimlar",
    meta: { requiresAuth: true, title: "Hodimlar" },
    component: () => import("../views/Hodimlar/Hodimlar.vue"),
  },
  {
    path: "/taminotlar",
    name: "taminotlar",
    meta: { requiresAuth: true, title: "Ta'minotlar" },
    component: () => import("../views/Taminotchilar/Taminotlar.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: { requiresAuth: true, title: "Sozlamalar" },
    component: () => import("../views/Setting.vue"),
  },
  {
    path: "/admin/:id",
    name: "admin",
    meta: { requiresAuth: true, title: "Admin" },
    component: () => import("../views/Admin/Admin.vue"),
  },
  {
    path: "/services",
    name: "services",
    meta: { requiresAuth: true, title: "Xizmatlarimiz" },
    component: () => import("../views/Services/Services.vue"),
  },
  {
    path: "/main",
    name: "main",
    meta: { requiresAuth: true, title: "Main" },
    component: () => import("../views/Main.vue"),
  },
  {
    path: "/taminoto/:id",
    name: "taminoto",
    meta: { requiresAuth: true, title: "taminoto" },
    component: () => import("../views/Taminotchilar/Taminotolish.vue"),
  },
  // {
  //   path: '/brands',
  //   name: 'brands',
  //   meta: { requiresAuth: true, title: "brands" },
  //   component: () => import('../views/Brands/Brands.vue')
  // },
  {
    path: "/product/:id",
    name: "product",
    meta: { requiresAuth: true, title: "product" },
    component: () => import("../views/Product/Product.vue"),
  },
  {
    path: "/products",
    name: "products",
    meta: { requiresAuth: true, title: "products" },
    component: () => import("../views/Products/Products.vue"),
  },
  {
    path: "/doimiy",
    name: "doimiy",
    meta: { requiresAuth: true, title: "doimiy" },
    component: () => import("../views/Chiqim/chiqim/Doimiy.vue"),
  },
  {
    path: "/customers",
    name: "customers",
    meta: { requiresAuth: true, title: "customers" },
    component: () => import("../views/Customers/Customers.vue"),
  },
  {
    path: "/cars/:id",
    name: "cars",
    meta: { requiresAuth: true, title: "cars" },
    component: () => import("../views/Cars/Carsid.vue"),
  },
  {
    path: "/carss",
    name: "carss",
    meta: { requiresAuth: true, title: "carss" },
    component: () => import("../views/Cars/Cars.vue"),
  },
  {
    path: "/recall",
    name: "recall",
    meta: { requiresAuth: true, title: "recall" },
    component: () => import("../views/Re_call_date/Re_call.vue"),
  },
  {
    path: "/srendered",
    name: "srendered",
    meta: { requiresAuth: true, title: "srendered" },
    component: () => import("../views/services_rendered/Services_rendered.vue"),
  },
  {
    path: "/in",
    name: "in",
    meta: { requiresAuth: true, title: "in" },
    component: () => import("../views/services_rendered/in.vue"),
  },
  {
    path: "/nasiya",
    name: "nasiya",
    meta: { requiresAuth: true, title: "nasiya" },
    component: () => import("../views/Nasiya/nasiya/loans.vue"),
  },
  {
    path: "/foyda",
    name: "foyda",
    meta: { requiresAuth: true, title: "foyda" },
    component: () => import("../views/Incomes/Incomes.vue"),
  },
  {
    path: "/tarix/:id",
    name: "tarix",
    meta: { requiresAuth: true, title: "tarix" },
    component: () => import("../views/Taminotchilar/Taminottarix.vue"),
  },
  {
    path: "/forcars/:id",
    name: "forcars",
    meta: { requiresAuth: true, title: "forcars" },
    component: () => import("../views/services_rendered/s_r_forcars.vue"),
  },
  {
    path: "/forspcars/:id",
    name: "forspcars",
    meta: { requiresAuth: true, title: "forspcars" },
    component: () => import("../views/services_rendered/for_cars.vue"),
  },
  {
    path: "/userid/:id",
    name: "userid",
    meta: { requiresAuth: true, title: "userid" },
    component: () => import("../views/Hodimlar/userid.vue"),
  },
  {
    path: "/sarfhodim",
    name: "sarfhodim",
    meta: { requiresAuth: true, title: "sarfhodim" },
    component: () => import("../views/Hodimlar/sarf_mahsulot.vue"),
  },
  {
    path: "/kpi",
    name: "kpi",
    meta: { requiresAuth: true, title: "kpi" },
    component: () => import("../views/Hodimlar/kpi.vue"),
  },
  {
    path: "/statistica",
    name: "statistica",
    meta: { requiresAuth: true, title: "statistica" },
    component: () => import("../views//Chart.js/chart.vue"),
  },
  {
    path: "/yakunlanganlar",
    name: "yakunlanganlar",
    meta: { requiresAuth: true, title: "yakunlanganlar" },
    component: () => import("../views/services_rendered/history_sr.vue"),
  },
  {
    path: "/table/:id",
    name: "table",
    meta: { requiresAuth: true, title: "table" },
    component: () => import("../views/services_rendered/table.vue"),
  },
  {
    path: "/kassa",
    name: "kassa",
    meta: { requiresAuth: true, title: "kassa" },
    component: () => import("../views/Kassa/Kassa.vue"),
  },
  {
    path: "/kassaid",
    name: "kassaid",
    meta: { requiresAuth: true, title: "kassaid" },
    component: () => import("../views/Kassa/Kassaid.vue"),
  },
  {
    path: "/loansend",
    name: "loansend",
    meta: { requiresAuth: true, title: "loansend" },
    component: () => import("../views/Nasiya/nasiya/loansend.vue"),
  },
  {
    path: "/kirim",
    name: "kirim",
    meta: { requiresAuth: true, title: "kirim" },
    component: () => import("../views/Kassa/Kirim.vue"),
  },
  {
    path: "/chiqim",
    name: "chiqim",
    meta: { requiresAuth: true, title: "chiqim" },
    component: () => import("../views/Kassa/Chiqim.vue"),
  },
  {
    path: "/nasiyadan",
    name: "nasiyadan",
    meta: { requiresAuth: true, title: "nasiyadan" },
    component: () => import("../views/Kassa/nasiyadankegan.vue"),
  },
  // {
  //   path: '/currency',
  //   name: 'currency',
  //   meta: { requiresAuth: true, title: "Valyuta" },
  //   component: () => import('../views/Currency/Currency.vue')
  // },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   meta: { requiresAuth: true, title: "Profil" },
  //   component: () => import('../views/Profile/Profile.vue')
  // },
];

routes = routes.filter((item) => {
  if (item.meta.role) {
    return item.meta.role == role;
  } else {
    return item;
  }
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("access_token") !== null;
  const role = localStorage.getItem("role");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next("/");
    }
  }
  next();
});

export default router;
