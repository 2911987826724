import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// --
import tabs from "./views/tabs.vue";
import VueApexCharts from "vue3-apexcharts";

createApp(App)
  .use(router)
  .use(VueApexCharts)
  .component("tabs", tabs)
  .mount("#app");
