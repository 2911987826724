<template>
  <div class="app">
    <span v-if="this.$route.name !== 'login'">
      <!-- <Sidebar v-if="role !== 'admin'" /> -->
    </span>
    <div
      class="container-fluid"
      id="body"
      style="height: 100vh"
      :class="this.$route.name === 'login' ? 'p-0' : 'pt-2'"
    >
      <div
        id="main-card"
        :class="
          this.$route.name !== 'login'
            ? 'card shadow main-card responsive'
            : 'h-100'
        "
      >
        <div
          class="btn-group btn-group-sm btn-admin"
          v-if="this.$route.name !== 'login' && this.$route.name !== 'main'"
        >
          <button class="btn btn-outline-secondary" @click="back()">
            <i class="fa fa-arrow-left" />
          </button>
          <router-link to="/main" class="btn btn-outline-secondary">
            <i class="fa fa-home" />
          </router-link>
        </div>
        <Router-view @setloading="setloading" />
      </div>
      <!-- <Notification v-if="role == 'branch_admin' && this.$route.name !== 'login'"/> -->
    </div>
    <isloading v-if="loading" />
  </div>
  <!-- offcanvas -->
  <!-- <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
    style="width: 333px"
  >
    <div class="offcanvas-header">
      <h5 class="text-center" id="offcanvasRightLabel">
        <i class="fa fa-cog fa-1x"></i> Sozlamalar
      </h5>
      <button
        type="button"
        class="btn-close btn-light text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body text-center">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="tabs"
            data-toggle="tab"
            href="#home"
            role="tab"
            aria-controls="home"
            aria-selected="true"
            ><i class="fa fa-user"></i
          ></a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="dollar"
            data-toggle="tab"
            href="#profile"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            ><i class="fa fa-dollar"></i
          ></a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tabs"
            data-toggle="tab"
            href="#contact"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
            ><i class="fa fa-palette"></i
          ></a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <Profile />
        </div>
        <div
          class="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <Currency />
        </div>
        <div
          class="tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <div class="container mt-5">
            <h4>bacckground navbar</h4>
            <button
              type="button"
              id="btn"
              class="btn btn-primary"
              @click="setHeaderBackground('blue')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-secondary"
              @click="setHeaderBackground('gray')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-success"
              @click="setHeaderBackground('green')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-danger"
              @click="setHeaderBackground('red')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-warning"
              @click="setHeaderBackground('yellow')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-info"
              @click="setHeaderBackground('cyan')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-light"
              @click="setHeaderBackground('white')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-dark"
              @click="setHeaderBackground('rgb(0, 0, 0, .5)')"
            ></button>
          </div>
          <div class="container mt-5">
            <h4>color fonts navbar</h4>
            <button
              type="button"
              id="btn"
              class="btn btn-primary"
              @click="setHeadercolor('blue')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-secondary"
              @click="setHeadercolor('gray')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-success"
              @click="setHeadercolor('green')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-danger"
              @click="setHeadercolor('red')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-warning"
              @click="setHeadercolor('yellow')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-info"
              @click="setHeadercolor('cyan')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-light"
              @click="setHeadercolor('white')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-dark"
              @click="setHeadercolor('rgb(0, 0, 0, .5)')"
            ></button>
          </div>

          <div class="container mt-5">
            <h4>background body</h4>
            <button
              type="button"
              id="btn"
              class="btn btn-primary"
              @click="setbodyBackground('blue')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-secondary"
              @click="setbodyBackground('gray')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-success"
              @click="setbodyBackground('green')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-danger"
              @click="setbodyBackground('red')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-warning"
              @click="setbodyBackground('yellow')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-info"
              @click="setbodyBackground('cyan')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-light"
              @click="setbodyBackground('white')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-dark"
              @click="setbodyBackground('rgb(0, 0, 0, .5)')"
            ></button>
          </div>

          <div class="container mt-5">
            <h4>background cards</h4>
            <button
              type="button"
              id="btn"
              class="btn btn-primary"
              @click="setcardchaBackground('blue')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-secondary"
              @click="setcardchaBackground('gray')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-success"
              @click="setcardchaBackground('green')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-danger"
              @click="setcardchaBackground('red')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-warning"
              @click="setcardchaBackground('yellow')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-info"
              @click="setcardchaBackground('cyan')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-light"
              @click="setcardchaBackground('white')"
            ></button>
            <button
              type="button"
              id="btn"
              class="btn btn-dark"
              @click="setcardchaBackground('rgb(0, 0, 0, .5)')"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- offcanvas end -->
</template>

<script>
// import Sidebar from "./components/sidebar/Sidebar.vue";
import swal from "sweetalert";
import isloading from "./components/Animation/Anime.vue";
import Swal from "sweetalert2";
export default {
  name: "App",
  components: { Notification, isloading },
  data() {
    return {
      role: localStorage.getItem("role"),
      loading: true,
      nightMode: localStorage.getItem("nightMode"),
    };
  },
  watch: {
    $route(to) {
      document.title = `Savdo • ${to.meta.title}`;
      this.loading = true;
      this.setDisplay();
    },
  },
  created() {
    this.nightMode = JSON.parse(localStorage.getItem("nightMode"));

    if (this.nightMode == true) {
      localStorage.setItem("nightMode", this.nightMode);
      document.querySelector("#app").classList.remove("dark");
    } else if (this.nightMode == false) {
      localStorage.setItem("nightMode", this.nightMode);
      document.querySelector("#app").classList.add("dark");
    }
    this.setDisplay();
    setInterval(() => {
      window.onoffline = () => {
        swal({
          icon: "warning",
          title: "Tarmoq bilan aloqa mavjud emas !",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      };
    }, 60000);
    window.ononline = () => {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Aloqa mavjud!",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {});
    };
  },
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1);
    },
    setloading(loading) {
      this.loading = loading;
    },
    setDisplay() {
      document.documentElement.style.setProperty(
        "--background-header-cards",
        localStorage.getItem("--background-header-cards")
      );
      document.documentElement.style.setProperty(
        "--color-header-cards",
        localStorage.getItem("--color-header-cards")
      );
      document.documentElement.style.setProperty(
        "--background-card-body",
        localStorage.getItem("--background-card-body")
      );
      document.documentElement.style.setProperty(
        "--background-cardchalar",
        localStorage.getItem("--background-cardchalar")
      );
    },
  },
};
</script>

<style>
@import url("./main.css");
@import url("./dark.css");
.btn-admin {
  position: absolute;
  z-index: 10;
}
#body {
  background-color: rgb(33, 41, 54);
}
/* #cog {
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 100;  
} */
/* #offcanvasRight {
  background-color: rgb(33, 40, 61);
  color: rgb(255, 255, 255);
  width: 250px;
} */
#tabs {
  width: 90px;
}
#dollar {
  width: 90px;
}
#btn {
  width: 30px;
  height: 30px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50%;
  margin: 5px;
}
</style>
